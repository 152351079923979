import React, { useState } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, ResponsiveImage, LanguageSelect } from '@/components';
import Plus from '@/assets/plus2.svg';
import Minus from '@/assets/minus2.svg';

const NavMobile = ({
  props,
  className,
  data,
  metadata,
  close,
  mobileBottomNavItems,
  showLanguageSwitcher,
  hotelVillaNavElements,
  isVilla,
  level,
}) => {
  const [active, setActive] = useState([]);
  const linkClassWithoutFlyout = 'interactive-01';
  const languageSwitcherTitleClass =
    '[&_.popover-title]:!text-[12px] [&_.popover-title]:!leading-[14px] [&_.popover-title]:!tracking-[2.4px] [&_li]:!text-[12px] [&_li]:!leading-[14px] [&_li]:!tracking-[2.4px]';

  const accordionColMotion = (index) => {
    return {
      initial: 'inactive',
      animate: active.includes(index) ? 'active' : 'inactive',
      variants: {
        inactive: {
          height: 0,
        },
        active: {
          height: 'auto',
        },
      },
      transition: {
        type: 'spring',
        stiffness: 120,
        damping: 30,
      },
    };
  };

  const toggleActive = (index) => {
    let newArray = [...active];
    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      newArray = [index];
    }
    setActive(newArray);
  };

  const navItemSpecialClassesLevel0 = (index) => {
    if (index === 1 && level === 0) {
      return 'pb-8 mb-0';
    } else if (index === 2 && level === 0) {
      return 'mb-2';
    } else if (index === 3 && level === 0) {
      return 'mb-2';
    } else if (index === 4 && level === 0) {
      return 'pb-[24px] border-b border-charcoal/25';
    } else if (index === 5 && level === 0) {
      return 'pt-[33px] pb-[26px]';
    } else if (index > 5 && level === 0) {
      return 'pb-[26px]';
    }
  };

  const countLength = (navItem) => {
    let count = 0;

    navItem.flyout?.map((f1) => {
      count += f1.flyout?.length;
    });

    return count;
  };

  const hotelNavigationClass =
    'interactive-01 mb-6 last:mb-0 last:pb-8 last:border-b last:border-charcoal/25 last:mb-8';

  return (
    <div {...props} className={classNames(className, 'flex flex-col justify-between px-4 pb-6 pt-10 text-charcoal')}>
      <div>
        {hotelVillaNavElements.length > 0 && !isVilla && (
          <div className="hotel-nav-wrapper">
            {hotelVillaNavElements.map((hotelItem, hotelIndex) => (
              <div key={hotelIndex} className={classNames(hotelNavigationClass, 'hotel-nav-items')}>
                <Link link={hotelItem.link} onClick={close}>
                  {hotelItem?.title || hotelItem.link.text}
                </Link>
              </div>
            ))}
          </div>
        )}
        {data?.map((navItem, navIndex) => {
          const count = countLength(navItem);

          return (
            <div
              key={navIndex}
              className={classNames(navItemSpecialClassesLevel0(navIndex + 1), navIndex + 1)}
              onClick={() => toggleActive(navIndex)}
            >
              {(navItem.navItemLink && navItem?.flyout?.length === 0) ||
              (navItem.navItemLink && navItem?.flyout === null) ||
              (navItem.ctaLink && navItem?.flyout?.length === 0) ||
              (navItem.ctaLink && navItem?.flyout === null) ? (
                <>
                  <div className={linkClassWithoutFlyout}>
                    <Link
                      link={
                        navItem.navItemLink ||
                        (navItem.ctaLink && {
                          ...navItem.ctaLink,
                          text: navItem.navigationalTitle || navItem.ctaLink.text,
                        })
                      }
                      onClick={close}
                    >
                      {navItem.navItemLink?.text || navItem.navigationalTitle || navItem.ctaLink?.text}
                    </Link>
                  </div>
                </>
              ) : (
                <div className="interactive-02 flex items-center justify-between">
                  <div
                    className={classNames(
                      'interactive-02',
                      active.includes(navIndex) ? 'font-semibold' : 'font-medium',
                    )}
                  >
                    {navItem.navItemText}
                    <span className="ml-1">({count > 0 ? count : navItem.flyout?.length})</span>
                  </div>
                  <div>
                    <AnimatePresence mode="wait">
                      {active.includes(navIndex) ? (
                        <motion.div key="minus" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                          <Minus />
                        </motion.div>
                      ) : (
                        <motion.div key="plus" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                          <Plus />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              )}

              <motion.div {...accordionColMotion(navIndex)} className="overflow-hidden">
                {navItem?.flyout?.map((firstLevelLink, firstLevelIndex) => (
                  <div
                    key={firstLevelIndex}
                    className="first-level-wrapper mb-4 first:mt-5 [&:nth-last-of-type(1)]:mb-0"
                  >
                    <div>
                      <Link
                        className={linkClassWithoutFlyout}
                        link={
                          firstLevelLink.navItemLink ||
                          (firstLevelLink.ctaLink && {
                            ...firstLevelLink.ctaLink,
                            text: firstLevelLink.navigationalTitle || firstLevelLink.ctaLink.text,
                          })
                        }
                        onClick={close}
                      >
                        {firstLevelLink.navItemLink?.text ||
                          firstLevelLink.navigationalTitle ||
                          firstLevelLink.ctaLink?.text}
                      </Link>
                    </div>

                    {firstLevelLink.flyout.length > 0 && (
                      <p className="label-01 mb-1 mt-5 text-charcoal/50">{firstLevelLink.navigationalTitle}</p>
                    )}
                    {firstLevelLink?.flyout?.map((secondLevelLink, secondLevelIndex) => (
                      <Link
                        key={secondLevelIndex}
                        className={classNames(
                          linkClassWithoutFlyout,
                          'flex border-b border-b-charcoal/25 py-4 text-black',
                        )}
                        link={
                          secondLevelLink.navItemLink ||
                          (secondLevelLink.ctaLink && {
                            ...secondLevelLink.ctaLink,
                            text: secondLevelLink.navigationalTitle || secondLevelLink.ctaLink.text,
                          })
                        }
                        onClick={close}
                      >
                        {secondLevelLink?.promoImage && (
                          <div className="mr-4 h-20 w-[120px]">
                            <ResponsiveImage
                              image={secondLevelLink?.promoImage}
                              widths={{ xs: 120, sm: 120, md: 120, lg: 120, xl: 942, xxl: 942 }}
                              heights={{ xs: 80, sm: 80, md: 80, lg: 80, xl: 792, xxl: 792 }}
                              className="pointer-events-none h-full w-full object-cover transition-all duration-300"
                            />
                          </div>
                        )}

                        <div className="body-02 max-w-[58%] capitalize text-black">
                          {secondLevelLink.navItemLink?.text ||
                            secondLevelLink.navigationalTitle ||
                            secondLevelLink.ctaLink?.text}
                        </div>
                      </Link>
                    ))}
                  </div>
                ))}
                {navItem.ctaLink && (
                  <Link
                    className={classNames('cta interactive-01 mt-4 inline-block text-charcoal')}
                    link={navItem.ctaLink}
                    onClick={close}
                  />
                )}
              </motion.div>
            </div>
          );
        })}
      </div>

      {(mobileBottomNavItems?.length > 0 || showLanguageSwitcher) && (
        <div className="mt-[6px] h-[192px] w-full bg-charcoal/5 px-4 py-6">
          <div className={classNames('justify-right tracking-8 cursor-pointer uppercase leading-4 duration-200')}>
            {mobileBottomNavItems?.length > 0 && (
              <div className="contact-details flex flex-col gap-7">
                {mobileBottomNavItems.map((bottomLink, bottomLinkIndex) => (
                  <Link
                    key={bottomLinkIndex}
                    className={classNames('interactive-02 capitalize')}
                    link={bottomLink}
                    onClick={close}
                  />
                ))}
              </div>
            )}
            {showLanguageSwitcher && (
              <LanguageSelect
                metadata={metadata}
                className={classNames(
                  languageSwitcherTitleClass,
                  mobileBottomNavItems?.length > 0 ? 'mt-3' : 'mt-0',
                  'z-50 w-fit [&_.arrow_svg_path]:duration-700 [&_.content]:!w-fit [&_.main-button]:gap-1 [&_.main-button]:!border-0 [&_.main-button]:!bg-transparent [&_.main-button]:px-0 [&_.popover-title]:!text-[12px] [&_.popover-title]:!font-medium [&_.popover-title]:!duration-700 [&_li]:!text-[12px] [&_li]:!font-medium',
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavMobile;
