/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Box, FormControl, PopoverMenu, Stack } from '@/components';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Search from '@/assets/search.svg';

export const fadeInUp = {
  variants: {
    inactive: {
      y: 50,
      opacity: 0,
    },
    active: {
      y: 0,
      opacity: 1,

      transition: {
        type: 'spring',
        damping: 25,
        delay: 0.5,
      },
    },
  },
};

const AvailabilityBarModel = ({ data }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [liftBookingBar, setLiftBookingBar] = useState(false);
  const { t } = useTranslation('common');
  const datepicker = useRef();
  const guests = useRef();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const heroSection = document.querySelector('section[class*="Hero"]');

      if (heroSection.classList.contains('HeroMediax1')) setLiftBookingBar(true);
    }
  }, []);

  const setDates = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newStartDate && newEndDate) {
      guests.current.open();
    }
  };

  const formValid = !!startDate && !!endDate;

  const format = (date) => {
    if (!date) return;
    let options = [{ year: 'numeric' }, { month: 'numeric' }, { day: 'numeric' }];
    function format(option) {
      let formatter = new Intl.DateTimeFormat('en', option);
      const formatted = formatter.format(date);
      if (formatted.length === 1) {
        return `0${formatted}`;
      }
      return formatted;
    }

    return options.map(format).join('-');
  };

  let primaryBookingUrl = null;
  let secondaryBookingUrl = null;

  if (data?.hotelBookingLink || data?.residencesBookingLink) {
    try {
      primaryBookingUrl =
        data?.residencesBookingLink && data?.suitesBookingLink
          ? new URL(data.residencesBookingLink.href)
          : new URL(data.hotelBookingLink.href);
      secondaryBookingUrl = data?.suitesBookingLink ? new URL(data.suitesBookingLink.href) : null;

      if (startDate) {
        if (primaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
          primaryBookingUrl.searchParams.set('arrivalDate', format(startDate));
        } else {
          primaryBookingUrl.searchParams.set('checkin', format(startDate));
        }

        if (secondaryBookingUrl) {
          if (secondaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
            secondaryBookingUrl.searchParams.set('arrivalDate', format(startDate));
          } else {
            secondaryBookingUrl.searchParams.set('checkin', format(startDate));
          }
        }
      }

      if (endDate) {
        if (primaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
          primaryBookingUrl.searchParams.set('departureDate', format(endDate));
        } else {
          primaryBookingUrl.searchParams.set('checkout', format(endDate));
        }

        if (secondaryBookingUrl) {
          if (secondaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
            secondaryBookingUrl.searchParams.set('departureDate', format(endDate));
          } else {
            secondaryBookingUrl.searchParams.set('checkout', format(endDate));
          }
        }
      }

      if (adults) {
        if (primaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
          primaryBookingUrl.searchParams.set('selectedAdultcount', adults);
        } else {
          primaryBookingUrl.searchParams.set('adults', adults);
        }

        if (secondaryBookingUrl) {
          if (secondaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
            secondaryBookingUrl.searchParams.set('selectedAdultcount', adults);
          } else {
            secondaryBookingUrl.searchParams.set('adults', adults);
          }
        }
      }

      if (children) {
        if (primaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
          primaryBookingUrl.searchParams.set('selectedChildcount', children);
        } else {
          primaryBookingUrl.searchParams.set('kids', children);
        }

        if (secondaryBookingUrl) {
          if (secondaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
            secondaryBookingUrl.searchParams.set('selectedChildcount', children);
          } else {
            secondaryBookingUrl.searchParams.set('kids', children);
          }
        }
      }

      if (primaryBookingUrl && !primaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
        primaryBookingUrl.href = primaryBookingUrl.href.replace('?', '#');
      }
      if (secondaryBookingUrl && !secondaryBookingUrl.href.includes('https://www.secure-hotel-booking.com/')) {
        secondaryBookingUrl.href = secondaryBookingUrl.href.replace('?', '#');
      }
    } catch (e) {
      console.error('Invalid URL: ' + data.residencesBookingLink);
    }
  }

  return (
    <div
      className={classNames(
        'absolute left-1/2 z-10 hidden w-[944px] -translate-x-1/2 lg:block',
        liftBookingBar ? '-mt-28' : '-mt-20',
      )}
    >
      <Stack
        motion={{
          ...fadeInUp,
          initial: 'inactive',
          animate: 'active',
        }}
      >
        <Box className="form-items-container flex flex-col gap-2 lg:flex-row [&_.content]:!border-none [&_.input-group_.main-button]:h-[48px] [&_.main-button]:!bg-silk">
          <div className="relative flex w-full flex-col gap-2 lg:flex-row">
            <div className="form-item dates w-full">
              <FormControl
                popoverRef={datepicker}
                type="datepicker"
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(event) => setDates(event.target.value)}
                placeholder={t('booking.$checkInCheckOut')}
                minDate={new Date(data?.nextBookableDate)}
                className={classNames(
                  `!static w-[344px] shadow-input`,
                  '[&_.react-datepicker]:w-[696px] [&_.react-datepicker]:rounded-lg [&_.react-datepicker]:!bg-silk',
                  '[&_.react-datepicker_.prev-buttons+span]:text-black/75',
                  '[&_.datepicker_.popover-title.placeholder]:!font-medium [&_.datepicker_.popover-title.placeholder]:!text-charcoal',
                )}
                monthsShown={2}
              />
            </div>
            <div className="form-item guests input-group mt-0 w-full">
              <PopoverMenu
                title={t('booking.$xAdultsXChildren', { adults, children })}
                className={classNames(`dropdown w-[344px] bg-transparent shadow-input`)}
                ref={guests}
                titleClass="before:content-guest flex items-center gap-2 !font-medium text-charcoal "
              >
                <div className="flex w-full flex-col gap-03 px-4 py-3">
                  <div className="flex h-[31px] items-center justify-between [&_.input-group]:!mt-0 [&_.number-input_input]:!w-[12px]">
                    <div className="body-03 text-charcoal">{t('booking.$adults')}</div>
                    <FormControl
                      type="number"
                      value={adults}
                      onChange={(event) => setAdults(event.target.value)}
                      minValue={1}
                      maxValue={data?.maxAdults}
                      className="input !w-28 !py-1 text-charcoal [&_input]:mx-3"
                    />
                  </div>
                  <div className="flex h-[31px] items-center justify-between [&_.input-group]:!mt-0 [&_.number-input_input]:!w-[12px]">
                    <div className="body-03 text-charcoal">{t('booking.$children')}</div>

                    <FormControl
                      type="number"
                      value={children}
                      onChange={(event) => setChildren(event.target.value)}
                      minValue={0}
                      maxValue={data?.maxChildren}
                      className="input !w-28 !py-1 text-charcoal [&_input]:mx-3"
                    />
                  </div>
                </div>
              </PopoverMenu>
            </div>
          </div>

          {primaryBookingUrl && secondaryBookingUrl === null && (
            <div>
              <a
                href={primaryBookingUrl.href}
                className="btn primary !h-12 w-fit whitespace-nowrap px-[12px]"
                aria-label={t('general.$newWindowAriaLabel', { ariaLabel: t('booking.$searchAvailability') })}
                target="_blank"
              >
                <Search />
                {t('booking.$searchAvailability')}
              </a>
            </div>
          )}
          {primaryBookingUrl && secondaryBookingUrl && (
            <div className="form-item guests input-group mt-0 w-full">
              <PopoverMenu
                title={t('booking.$searchAvailability')}
                hideArrow={true}
                titleClass="before:content-search flex items-center gap-2 !font-medium text-charcoal "
                bgClass={'bg-transparent'}
                className={classNames(`dropdown w-[240px] bg-transparent shadow-input`)}
              >
                <div className="body-03 flex w-full flex-col gap-03 px-4 py-3">
                  <a
                    href={primaryBookingUrl.href}
                    aria-label={t('general.$newWindowAriaLabel', { ariaLabel: t('booking.$searchAvailability') })}
                    target="_blank"
                    className="flex h-[31px] items-center justify-between [&_.input-group]:!mt-0 [&_.number-input_input]:!w-[12px]"
                  >
                    {data.residencesBookingLink.text}
                  </a>
                  <a
                    href={secondaryBookingUrl.href}
                    aria-label={t('general.$newWindowAriaLabel', { ariaLabel: t('booking.$searchAvailability') })}
                    target="_blank"
                    className="flex h-[31px] items-center justify-between [&_.input-group]:!mt-0 [&_.number-input_input]:!w-[12px]"
                  >
                    {data.suitesBookingLink.text}
                  </a>
                </div>
              </PopoverMenu>
            </div>
          )}
        </Box>
      </Stack>
    </div>
  );
};

export default AvailabilityBarModel;
